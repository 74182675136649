<template lang="html">
  <v-container grid-list-md align-center justify-center pt-0 pl-0 pr-0 pb-0>
    <ComponentPlantTop @activeMenu="activeMenu"/>
  </v-container>
</template>

<script>
import ComponentPlantTop from '@/components/ComponentPlantTop';
export default {
  components: {
    ComponentPlantTop,
  },
  data(){
    return{
      active: ''
    }
  },
  methods:{
    gotoLink(link){
      this.$router.push(link)
    },
    activeMenu(status){
      this.$emit('activeMenu', status)
    }
  }
}
</script>
