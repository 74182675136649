<template>
  <v-container grid-list-md pt-0>
    <div style="margin-top:17rem !important" v-if="isLoading">
      <v-layout row wrap flex justify-center align-center fill-height>
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </v-layout>
    </div>
    <div class="" v-if="!isLoading && listPlant.length !== 0">
      <v-layout row wrap mt-1 justify-center mb-3>
        <!-- <v-flex md12 xs12 sm12 style="text-align:center">
          <v-btn depressed @click="sort()">
            <v-icon>sort</v-icon>
            เรียงลำดับ
          </v-btn>
        </v-flex> -->
        <!-- <v-flex md4 xs12 sm6>
          <v-card flat color="secondary">
            <v-text-field
            v-model="search"
            label="Search..."
            solo
            hide-details
            clearable
            clear-icon="mdi-close-circle-outline"
            append-icon="search"
            ></v-text-field>
          </v-card>
        </v-flex> -->
      </v-layout>
      <v-layout row wrap align-center justify-center>
        <v-flex md6 xs12 sm6>
          <v-layout row wrap align-center justify-center>
            <v-flex md12 xs12 sm12 v-for="(i,index) in filteredList" :key="index">
              <v-card flat color="blue" >
                <v-card-text>
                  <span class="title white--text">อันดับ {{index + 1}}: <span style="font-size:26px !important" >{{i.plant_name}} {{commaSeparateNumber(i.sumPlant)}} ต้น</span></span><br>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-layout row wrap v-if="filteredList.length === 0" justify-center mt-2>
        <v-flex xs1>
          <h2>ไม่พบข้อมูล</h2>
        </v-flex>
      </v-layout>
    </div>
  </v-container>
</template>

<script>
export default {
  data (){
    return{
      search: '',
      totalPlant: '',
      listPlant: [],
      isSort: true,
      isLoading: true,
      numberOfPeopleByTotlePlant: '',
      userRegister: ''
    }
  },
  created(){
    this.isLoading = true
    this.queryPlantTopFifteen()
    this.$emit('activeMenu', 'plant_top')
  },
  computed:{
    filteredList() {
      return this.listPlant.filter(post => {
        return post.plant_name.toLowerCase().includes(this.search.toLowerCase())
      })
    },
  },
  methods: {
    queryPlantTopFifteen(){
      this.axios.post(process.env.VUE_APP_IPFLASK + '/queryPlantTopFifteen').then((response) => {
        // console.log(response.data);
        this.listPlant = response.data
        this.isLoading = false
      })
    },
    commaSeparateNumber(val){
      while (/(\d+)(\d{3})/.test(val.toString())){
        val = val.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
      }
      return val;
    },
    sort(){
      if (this.isSort){
        this.isSort = false
        return this.listPlant.sort(function(a, b) {
          return b.sumPlant - a.sumPlant
        });
      } else {
        this.isSort = true
        return this.listPlant.sort(function(a, b) {
          return a.sumPlant - b.sumPlant
        });
      }
    }
  }

}
</script>

<style scoped>
.lineheight{
  line-height: 0.65 !important;
}
.lineheight30{
  line-height: 40px !important;
}
</style>
